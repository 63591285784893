﻿/*!
* Project Name: BuildTab Docs
* Project Description:A viewer for BIM CDE by BuildTab.
* @version 1.0.7- 2024-20-08
* @author Hai Minh
* @copyright 2024 BuildTab
* @license MIT
*
*/

import * as THREE from 'three';
import { CameraProjections, IfcViewerAPI } from 'web-ifc-viewer';
import { createSideMenuButton } from './utils/gui-creator';
import {
    IFCWALLSTANDARDCASE, IFCSLAB, IFCDOOR, IFCWINDOW, IFCFURNISHINGELEMENT, IFCMEMBER, IFCPLATE, IFCSPACE, IFCOPENINGELEMENT, IFCBUILDINGSTOREY, IFCOBJECT,
    IFCBEAM, IFCCOLUMN, IFCROOT
} from 'web-ifc';
import {
    MeshBasicMaterial, Sprite, SpriteMaterial,
    LineBasicMaterial, MeshLambertMaterial,
    Color,
    Vector2, Vector3, Raycaster,
    DepthTexture, WebGLRenderer, BoxHelper,
    WebGLRenderTarget, Material, BufferGeometry, BufferAttribute, Mesh, MeshPhongMaterial, Camera, PerspectiveCamera, OrthographicCamera, Clock, Plane
} from 'three';
import { CSS2DRenderer, CSS2DObject } from './node_modules/three/examples/jsm/renderers/CSS2DRenderer.js';

import { ClippingEdges } from 'web-ifc-viewer/dist/components/display/clipping-planes/clipping-edges';
import { NavCube } from "./NavCube/NavCube";
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DXFViewer } from './src/dxfViewer';
import { Merger } from './src/utils/merger';
import { SnapsHelper } from './src/utils/snapsHelper';
import { Hover } from './src/utils/hover';
import { Select } from './src/utils/select';
import { Boilerplate } from './boiler/boilerplate';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';



let viewer;
const container = document.getElementById('viewer-container');
viewer = new IfcViewerAPI({
    container: container,
    backgroundColor: new THREE.Color('rgb(240, 240, 240)')
});

//const ambientLight = new THREE.AmbientLight(new THREE.Color('rgb(255, 255, 255)'), 0.8);
//viewer.context.scene.add(ambientLight);

viewer.shadowDropper.darkness = 15;
viewer.context.renderer.postProduction.active = true;

const preselectMat = new THREE.MeshLambertMaterial({
    transparent: true,
    opacity: 0.95,
    color: 0x7ddeff,
    side: THREE.DoubleSide
});

const selectMat = new THREE.MeshLambertMaterial({
    color: 0x0099cc,
    emissive: 0x0077cc,
    transparent: true,
    opacity: 0.95,
    side: THREE.DoubleSide
});

const highlightMat = new THREE.MeshLambertMaterial({
    color: 0x00BFFF,
    emissive: 0x0088ff,
    transparent: true,
    opacity: 0.95,
    side: THREE.DoubleSide
});


viewer.IFC.selector.preselection.material = preselectMat;
viewer.IFC.selector.selection.material = selectMat;
viewer.IFC.selector.highlightIfcItem.material = highlightMat;


async function setUpMultiThreading() {
    //const manager = viewer.IFC.loader.ifcManager;
    //await manager.useWebWorkers(true, 'files/IFCWorker.js');
    await viewer.IFC.setWasmPath('./../files/');

}

setUpMultiThreading();

viewer.IFC.loader.ifcManager.applyWebIfcConfig({
    USE_FAST_BOOLS: true,
    COORDINATE_TO_ORIGIN: true
});

let model;
viewer.IFC.loader.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);
const loadIfc = async (input) => {
    window.parent.postMessage({ action: 'onViewerLoad' }, '*');

    highlightSelected();
    let file;

    if (typeof input === 'string') {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', input, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function (event) {
            if (event.lengthComputable) {
                const percentComplete = Math.floor((event.loaded / event.total) * 50);
                updateProgressBar(percentComplete);
            }
        };

        xhr.onload = async function () {
            if (xhr.status === 200) {
                const blob = xhr.response;
                file = new File([blob], decodeURIComponent(input.split('/').pop()), { type: 'application/octet-stream' });

                await processIfcFile(file);

                updateProgressBar(100);
            } else {
                console.error('Failed to download file:', xhr.statusText);
            }
        };

        xhr.onerror = function () {
            console.error('Network error while downloading file.');
        };

        xhr.send();


    } else if (input instanceof Blob) {
        const blobName = 'downloaded.ifc';
        file = new File([input], blobName, { type: 'application/octet-stream' });
        await processIfcFile(file);
    } else if (input instanceof File) {
        file = input;
        await processIfcFile(file);
    } else {
        console.error('Unknown input type for loading.');
        return;
    }
};
let currentModelID = null;
const processIfcFile = async (file) => {
    try {
        if (currentModelID !== null) {
            viewer.IFC.loader.ifcManager.close(currentModelID, viewer.context.scene);
            if (viewer.shadowDropper.shadows[currentModelID]) {
                viewer.shadowDropper.deleteShadow(currentModelID.toString());
                viewer.IFC.loader.ifcManager.clearSubset(currentModelID);
                currentModelID = null;
            }
        }

        viewer.IFC.loader.ifcManager.setOnProgress((event) => {
            const renderProgress = Math.floor((event.loaded * 50) / event.total);
            updateProgressBar(50 + renderProgress);
        });

        viewer.IFC.loader.ifcManager.parser.setupOptionalCategories({
            [IFCSPACE]: false,
            [IFCOPENINGELEMENT]: false
        });

        model = await viewer.IFC.loadIfc(file, false);
        currentModelID = model.modelID;

        if (viewer.shadowDropper.shadows[model.modelID]) {
            viewer.shadowDropper.deleteShadow(model.modelID.toString());
        }

        await viewer.shadowDropper.renderShadow(model.modelID);

        const { versionID } = getIdsFromViewUrl();
        if (versionID) {
            const fileData = await fetchFileDataByVersionId(versionID);
            if (fileData && fileData.originalName) {
                const fileExtension = fileData.originalName.split('.').pop().toLowerCase();
                if (fileExtension === 'dwg') {
                    model.traverse((child) => {
                        if (child.isMesh) {
                            child.material = new THREE.MeshPhongMaterial({
                                color: 0xCCCCCC,
                                emissive: 0x202020,
                                specular: 0x555555,
                                shininess: 30,
                                side: THREE.DoubleSide
                            });
                        }
                    });
                }
            } else {
                console.error('Failed to retrieve file data or originalName is missing');
            }
        } else {
            console.error('No version ID found in URL');
        }

        window.parent.postMessage({ type: 'onViewerLoaded', modelType: '3D' }, '*');

        exportModelToJsonAndProcess(model);

    } catch (error) {
        console.error('Error during loading process:', error);
    }
};


async function fetchFileDataByVersionId(versionId) {
    const accessToken = await getAccessToken();
    const fileByVersionUrl = `https://api-docs.buildtab.vn/api/v1/files/get-file-by-version-id?versionId=${versionId}`;

    try {
        const response = await fetch(fileByVersionUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch file information');
        }
        const fileData = await response.json();
        return fileData;
    } catch (error) {
        console.error('Error fetching file version details:', error);
        return null;
    }
}



document.addEventListener('click', onDocumentMouseClick, false);

function onDocumentMouseClick(event) {
    //event.preventDefault();

    const mouse = new THREE.Vector2();
    const raycaster = new THREE.Raycaster();
    const camera = viewer.context.getCamera();

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(viewer.context.getScene().children, true);

    //if (intersects.length > 0) {
    //    console.log('Object clicked:', intersects[0].object);
    //}
}


async function exportModelToJsonAndProcess(model) {
    try {
        const blobs = await viewer.IFC.properties.serializeAllProperties(model);
        const reader = new FileReader();

        reader.onload = function () {
            const text = reader.result;
            const data = JSON.parse(text);
            const revitIDExpressIDMap = {};

            Object.values(data).forEach((item) => {
                if (item.Tag && item.Tag.value) {
                    const revitID = item.Tag.value;
                    const expressID = item.expressID;
                    revitIDExpressIDMap[revitID] = expressID;
                }
            });

            sessionStorage.setItem('revitIDExpressIDMap', JSON.stringify(revitIDExpressIDMap));
        };

        reader.readAsText(blobs[0]);
    } catch (error) {
        console.error('Error exporting model to JSON:', error);
    }
}


const loadMultipleIfc = async (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        await loadIfc(file);
    }


};

const inputElement = document.createElement('input');
inputElement.addEventListener('change', loadMultipleIfc, false);
inputElement.setAttribute('type', 'file');
inputElement.setAttribute('multiple', '');
inputElement.classList.add('hidden');


let isMultiviewsEnabled = false;

//document.getElementById('multiviewsSwitch').addEventListener('change', (event) => {
//    isMultiviewsEnabled = event.target.checked;
//});


const handleKeyDown = async (event) => {
    if (event.code === 'Delete') {
        viewer.clipper.deletePlane();
        viewer.dimensions.delete();
    }
    if (event.code === 'Escape') {
        viewer.IFC.selector.unHighlightIfcItems();
    }
    //if (event.code === 'KeyC') {
    //    viewer.context.ifcCamera.toggleProjection();
    //}
    //if (event.code === 'KeyD') {
    //    viewer.IFC.removeIfcModel(0);
    //}
};

window.onmousemove = () => viewer.IFC.selector.prePickIfcItem();
window.onkeydown = handleKeyDown;

let showBimPopup = false;
let lastSelectedObject = null;
const ifcInfoButton = document.getElementById('ifcinfor_button');
const bimpopup = document.getElementById('bimpopup');


ifcInfoButton.addEventListener('click', () => {
    showBimPopup = !showBimPopup;
    ifcInfoButton.classList.toggle('highlighted', showBimPopup);

    bimpopup.style.display = showBimPopup ? 'block' : 'none';

});



function encodeObjectToJSON(object) {
    const objectInfo = {
        ID: object.id, // ID của đối tượng
        position: object.position,
        rotation: object.rotation,
        scale: object.scale,
        // Thêm các thông tin khác mà bạn muốn lưu
    };
    return JSON.stringify(objectInfo);
}


//let currentHighlighted = [];

//window.addEventListener('click', (event) => {
//    if (event.button !== 0) return;

//    // Reset highlight cho tất cả mesh đã highlight trước đó
//    resetHighlights();

//    const mouse = new THREE.Vector2(
//        (event.clientX / window.innerWidth) * 2 - 1,
//        -(event.clientY / window.innerHeight) * 2 + 1
//    );

//    const raycaster = new THREE.Raycaster();
//    raycaster.setFromCamera(mouse, viewer.context.getCamera());

//    const intersects = raycaster.intersectObjects(viewer.context.getScene().children, true);

//    if (intersects.length > 0) {
//        const selectedMesh = intersects[0].object;
//        const selectedID = selectedMesh.id; // Lấy ID của mesh được click

//        // Highlight tất cả các mesh có chung ID
//        highlightSharedIDMeshes(selectedID);
//    }
//});

//function resetHighlights() {
//    currentHighlighted.forEach(mesh => {
//        if (mesh.userData.originalMaterial) {
//            mesh.material = mesh.userData.originalMaterial;
//            delete mesh.userData.originalMaterial;
//        }
//    });
//    currentHighlighted = [];
//}

//function highlightSharedIDMeshes(selectedID) {
//    viewer.context.getScene().traverse((obj) => {
//        if (obj.isMesh && obj.id === selectedID) {
//            if (!obj.userData.originalMaterial) {
//                obj.userData.originalMaterial = obj.material;
//            }
//            obj.material = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide });
//            currentHighlighted.push(obj);
//        }
//    });
//}

let isBimInfoActive = false;
window.addEventListener('message', function (event) {
    const { type, value } = event.data;
    switch (type) {
        case 'onActivateBimInfo':
            isBimInfoActive = true;
            break;
        case 'onQuitBimInfo':
            isBimInfoActive = false;
            break;
    }
});

window.onclick = async (event) => {
    if (event.button === 0) {
        const result = await viewer.IFC.selector.pickIfcItem();
        if (result && result.id != null) {
            lastSelectedObject = result;

            if (isBimInfoActive) {
                showBimInfo(result);
            }
        } else {
            viewer.IFC.selector.unpickIfcItems();
        }
    }
};

window.ondblclick = async (event) => {
    if (isComparisonPopupVisible) {
        event.stopPropagation();
        return;
    }
    if (!isPinIssue && !isMeasuring) {
        if (viewer.clipper.active) {
            viewer.clipper.createPlane();
        } else {
            const result = await viewer.IFC.selector.highlightIfcItem(false);
            console.log(result);
            if (result && isBimInfoActive) {
                lastSelectedObject = result;
                showBimInfo(result);
            } else {
                viewer.IFC.selector.unHighlightIfcItems();
                viewer.IFC.selector.unpickIfcItems();
            }
        }
    }
};

async function showBimInfo(result) {
    const props = await viewer.IFC.getProperties(result.modelID, result.id, true, false);
    const popupContent = document.getElementById('bimpopup-content');
    popupContent.innerHTML = jsonToHtml(props);
    window.parent.postMessage({
        action: 'onBimObjectClicked',
        data: JSON.stringify(props)
    }, '*');
    //bimpopup.style.display = 'block';
}


//async function fetchAdditionalProperties(tagValue) {
//    try {
//        const response = await fetch('./FileSV/Compilation 2/{3D}.json');
//        const data = await response.json();

//        const collectionData = data.data?.collection || {};
//        const additionalInfo = collectionData[tagValue];

//        if (additionalInfo) {
//            //console.log(additionalInfo);
//            displayAdditionalInfoPopup(additionalInfo);
//        } else {
//            console.log(`No additional info found for Tag value: ${tagValue}`);
//        }
//    } catch (error) {
//        console.error('Error fetching additional properties:', error);
//    }
//}

//function displayAdditionalInfoPopup(info) {
//    let additionalInfoPopup = document.getElementById('additionalInfoPopup');
//    if (!additionalInfoPopup) {
//        // Nếu popup chưa tồn tại, tạo và thêm vào DOM
//        additionalInfoPopup = document.createElement('div');
//        additionalInfoPopup.id = 'additionalInfoPopup';
//        document.body.appendChild(additionalInfoPopup);
//    }

//    additionalInfoPopup.innerHTML = `
//        <div id="additionalInfoPopup-header">
//            <h3>Thông tin BIM</h3>
//            <button id="close-additionalInfoPopup">
//                <img src="resources/Closepopupbutton.svg" alt="Close">
//            </button>
//        </div>
//        <div id="additionalInfoPopup-content">
//            ${jsonToHtml(info)}
//        </div>
//    `;

//    additionalInfoPopup.style.display = 'block';

//    document.getElementById('close-additionalInfoPopup').onclick = function () {
//        additionalInfoPopup.style.display = 'none';
//    };
//}

//Setup UI
//Nave cube
viewer.container = container;
const navCube = new NavCube(viewer);
navCube.onPick(viewer.model);

document.getElementById('homeviewcube').addEventListener('click', function () {
    viewer.context.fitToFrame();
});

///////////////////////Progress bar////////////////////////////////
function updateProgressBar(percentage) {
    document.getElementById('file-download-progress').style.width = percentage + '%';
}

////////// BIM info popup///////////////////////////
function jsonToHtml(json, container = null) {
    if (!container) {
        container = document.createElement('div');
        container.className = 'properties-container';
    } else {
        container.innerHTML = '';
    }

    for (let groupName in json) {
        let group = json[groupName];
        let details = container.querySelector(`.property-group[data-group-name="${groupName}"]`);

        if (!details) {
            details = document.createElement('details');
            details.className = 'property-group';
            details.setAttribute('data-group-name', groupName);
            const summary = document.createElement('summary');
            summary.textContent = groupName;
            details.appendChild(summary);
        } else {
            details.innerHTML = '';
        }

        const table = document.createElement('table');
        table.className = 'properties-table';

        for (let propName in group) {
            let value = group[propName];

            if (value === null || value === undefined || value === '') continue;

            const row = document.createElement('tr');
            row.className = 'property-item';
            const nameCell = document.createElement('td');
            nameCell.className = 'property-name';
            nameCell.textContent = propName;
            row.appendChild(nameCell);

            const valueCell = document.createElement('td');
            valueCell.className = 'property-value';
            valueCell.innerHTML = typeof value === 'object' && value !== null ? jsonToHtml(value, document.createElement('div')) : value;
            row.appendChild(valueCell);

            table.appendChild(row);
        }

        details.appendChild(table);
        container.appendChild(details);
    }

    return container.outerHTML;
}


//function makeAdditionalPopupDraggable() {
//    const additionalPopup = document.getElementById('additionalInfoPopup');
//    const additionalHeader = document.getElementById('additionalInfoPopup-header');
//    if (!additionalPopup || !additionalHeader) {
//        console.error("Some elements are not found!");
//        return;
//    }

//    let mouseDown = false;
//    let offset = { x: 0, y: 0 };

//    additionalHeader.addEventListener('mousedown', (event) => {
//        mouseDown = true;

//        offset = {
//            x: event.clientX - additionalPopup.offsetLeft,
//            y: event.clientY - additionalPopup.offsetTop,
//        };
//    });

//    document.addEventListener('mousemove', (event) => {
//        if (mouseDown) {
//            additionalPopup.style.left = `${event.clientX - offset.x}px`;
//            additionalPopup.style.top = `${event.clientY - offset.y}px`;
//        }
//    });

//    document.addEventListener('mouseup', () => {
//        mouseDown = false;
//    });

//    const closeButton = document.getElementById("close-additionalInfoPopup");
//    closeButton.addEventListener("click", function () {
//        additionalPopup.style.display = "none";
//    });
//}


function makeDraggable(popupId, headerId) {
    // Lấy phần tử popup và header
    const bimpopup = document.getElementById(popupId);
    const bimheader = document.getElementById(headerId);

    let mouseDown = false;
    let offset = { x: 0, y: 0 };

    bimheader.addEventListener('mousedown', (event) => {
        mouseDown = true;

        offset = {
            x: event.clientX - bimpopup.offsetLeft,
            y: event.clientY - bimpopup.offsetTop,
        };
    });

    document.addEventListener('mousemove', (event) => {
        if (mouseDown) {
            bimpopup.style.left = `${event.clientX - offset.x / 2}px`;
            bimpopup.style.top = `${event.clientY - offset.y / 2}px`;
        }
    });

    document.addEventListener('mouseup', () => {
        mouseDown = false;
    });

    const closeButton = document.getElementById("close-bimpopup");
    closeButton.addEventListener("click", function () {
        bimpopup.style.display = "none";
        showBimPopup = false;
        showPopup('info', 'Đã tắt popup xem thông tin');
        ifcInfoButton.classList.remove('highlighted');

    });
}

document.addEventListener("DOMContentLoaded", function () {
    makeDraggable('bimpopup', 'bimpopup-header');
    //makeAdditionalPopupDraggable();
    const closeButton = document.getElementById('close-bimpopup');
    const bimpopup = document.getElementById('bimpopup');

    closeButton.addEventListener('click', () => {
        bimpopup.style.display = 'none';
    });
});

/////info popup//////////////
document.getElementById('minimize-infopopup').addEventListener('click', () => {
    const popup = document.getElementById('info-popup');
    const minimizedIcon = document.getElementById('minimized-popup-icon');
    popup.style.display = 'none';
    minimizedIcon.style.display = 'block';
});

document.getElementById('minimized-popup-icon').addEventListener('click', () => {
    const popup = document.getElementById('info-popup');
    const minimizedIcon = document.getElementById('minimized-popup-icon');
    popup.style.display = 'block';
    minimizedIcon.style.display = 'none';
});

document.getElementById('close-infopopup').addEventListener('click', () => {
    const popup = document.getElementById('info-popup');
    popup.style.display = 'none';
});
document.getElementById('do-not-show-again').addEventListener('change', (event) => {
    if (event.target.checked) {
        localStorage.setItem('hideInfoPopup', 'true');
    } else {
        localStorage.removeItem('hideInfoPopup');
    }
});
window.onload = () => {
    if (localStorage.getItem('hideInfoPopup') === 'true') {
        document.getElementById('info-popup').style.display = 'none';
    }
};


//////////////////////////////////////////////////////////////Slidebar////////////////////
function toggleViewerAndSheetDisplay(activeTab) {
    const viewerContainer = document.getElementById('viewer-container');
    const sheetViewerDiv = document.getElementById('sheetViewer');

    if (viewerContainer && sheetViewerDiv) {
        if (activeTab === 'sheets') {
            viewerContainer.style.display = 'none';
            sheetViewerDiv.style.display = 'block';
        } else if (activeTab === 'view3D') {
            viewerContainer.style.display = 'block';
            sheetViewerDiv.style.display = 'none';
        }
    }
}


document.addEventListener('DOMContentLoaded', function () {
    const tabs = document.querySelectorAll('.tab-button');
    const contents = document.querySelectorAll('.tab-content');
    const contentContainer = document.getElementById('content-container');
    const toggleContent = document.querySelector('.toggle-content');
    let isOpen = false;

    tabs.forEach(tab => {
        tab.addEventListener('click', () => {
            if (isOpen && tab.classList.contains('selected')) {
                contentContainer.style.width = '0';
                contentContainer.classList.remove('open');
                isOpen = false;
                return;
            }

            contentContainer.style.width = '350px';
            contentContainer.classList.add('open');
            isOpen = true;

            tabs.forEach(t => t.classList.remove('active'));
            tabs.forEach(t => t.classList.remove('selected'));
            tab.classList.add('active');
            tab.classList.add('selected');

            const target = tab.getAttribute('data-tab');
            contents.forEach(c => {
                if (c.id === target) {
                    c.classList.add('active');
                } else {
                    c.classList.remove('active');
                }
            });
        });
        toggleViewerAndSheetDisplay('view3D');
    });

    window.addEventListener('message', function (event) {
        const contentContainer = document.getElementById('content-container');

        if (event.data && typeof event.data === 'object') {
            switch (event.data.type) {
                case 'onOpenViewSheetContent':
                    contentContainer.style.width = '350px';
                    contentContainer.classList.add('open');
                    break;
                case 'onCloseViewSheetContent':
                    contentContainer.style.width = '0';
                    contentContainer.classList.remove('open');
                    break;
            }
        }
    });


    toggleContent.addEventListener('click', () => {
        contentContainer.style.width = '0';
        contentContainer.classList.remove('open');
        isOpen = false;
    });


    const innerTabs = document.querySelectorAll('.inner-tab-button');
    const innerContents = document.querySelectorAll('.inner-tab-content');

    innerTabs.forEach(innerTab => {
        innerTab.addEventListener('click', () => {
            innerTabs.forEach(t => t.classList.remove('active'));
            innerTab.classList.add('active');

            const target = innerTab.getAttribute('data-innertab');
            toggleViewerAndSheetDisplay(target);

            innerContents.forEach(c => {
                if (c.id === target) {
                    c.classList.add('active');
                } else {
                    c.classList.remove('active');
                }
            });
        });
    });
});
async function loadManifest(manifestUrl) {
    try {
        showNotification("Đang tải thông tin ban đầu của mô hình...");

        const queryParams = new URLSearchParams(window.location.search);
        let viewUrlParam = queryParams.get('viewurl');

        // ✅ Loại bỏ dấu `/` ở đầu nếu có
        if (viewUrlParam && viewUrlParam.startsWith('/')) {
            viewUrlParam = viewUrlParam.substring(1);
        }

        // ✅ Nếu không có viewurl → vẫn chạy flow mới (ver 2) với null
        if (!viewUrlParam) {
            console.warn("⚠️ viewurl không có, vẫn chạy flow manifest ver 2 với null.");
            loadIFCVer2(null);
            hideNotification();
            return;
        }

        try {
            console.log(`📡 Kiểm tra manifest mới với viewurl: ${viewUrlParam}`);
            const response = await fetch(`/api/get-manifest?viewurl=${viewUrlParam}`);

            if (response.ok) {
                console.log("✅ Phát hiện manifest ver 2, chạy loadIFCVer2...");
                const manifestData = await response.json();
                loadIFCVer2(viewUrlParam);
                hideNotification();
                return;
            } else {
                console.warn("⚠️ Không tìm thấy manifest ver 2, tiếp tục flow cũ...");
            }
        } catch (error) {
            console.warn("⚠️ API manifest ver 2 lỗi, tiếp tục flow cũ...", error);
        }

        // 🔄 Nếu không có manifest mới, chạy flow cũ
        return runLegacyManifestFlow(manifestUrl);
    } catch (error) {
        console.error('❌ Lỗi khi tải hoặc xử lý Manifest:', error);
        hideNotification();
    }
}


function loadIFCVer2(viewurl) {
    try {
        console.log(`🚀 Đang tải IFC Viewer với viewurl: ${viewurl}`);
        document.getElementById('loadmodePopup').style.visibility = 'hidden';
        document.getElementById('viewer-container').style.display = 'none';

        // Xóa iframe cũ nếu tồn tại
        const oldIframe = document.getElementById('3dViewerIframe');
        if (oldIframe) {
            console.log("🔄 Đang xoá iframe cũ...");
            oldIframe.remove();
        }

        // Tạo iframe mới
        const iframe = document.createElement('iframe');
        iframe.id = '3dViewerIframe';

        // Truyền viewurl vào iframe để ifcviewer/index.html có thể đọc
        iframe.src = `/ifcviewer/index.html?viewurl=${encodeURIComponent(viewurl)}`;

        // Thiết lập style cho iframe
        iframe.style.position = 'fixed';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.width = '100vw';
        iframe.style.height = '100vh';
        iframe.style.zIndex = '1100';
        iframe.style.overflow = 'hidden';
        iframe.style.border = 'none';


        // Thêm iframe vào body
        document.body.appendChild(iframe);
        window.parent.postMessage({ type: 'onViewerLoaded', modelType: '3D' }, '*');

        console.log("✅ IFC Viewer đã được nhúng thành công.");
    } catch (error) {
        console.error("❌ Lỗi khi tạo iframe cho IFC Viewer:", error);
    }
}


// 🛠 Tách logic flow cũ ra để dễ quản lý
async function runLegacyManifestFlow(manifestUrl) {
    try {
        console.log("🔄 Đang tải manifest theo flow cũ...");
        const responseNwd = await fetch(`/api/load-manifest-nwd?path=${encodeURIComponent(manifestUrl)}`);
        if (!responseNwd.ok) {
            throw new Error('Server response was not ok.');
        }
        const manifestDataNwd = await responseNwd.json();

        const glbPaths = manifestDataNwd.glbFiles;
        const ifcPaths = manifestDataNwd.ifcFiles;

        if (glbPaths && glbPaths.length > 0) {
            const manifestJSON = JSON.stringify(manifestDataNwd.manifest);
            const blob = new Blob([manifestJSON], { type: 'application/json' });
            const manifestBlobUrl = URL.createObjectURL(blob);

            document.getElementById('viewer-container').style.display = 'none';
            displayComponentFiles(extractTopLevelComponentFiles(manifestDataNwd.manifest));

            hideNotification();
            loadNwdModelIframe(manifestBlobUrl, ifcPaths && ifcPaths.length > 0);
        } else {
            const response = await fetch(`/api/load-manifest?path=${encodeURIComponent(manifestUrl)}`);
            if (!response.ok) {
                throw new Error('Server response was not ok.');
            }
            const manifestData = await response.json();
            checkManifestContent(manifestData);

            hideNotification();
            document.getElementById('loadmodePopup').style.display = 'none';
            await updateUIWithManifestData(manifestData);
        }
    } catch (error) {
        console.error("❌ Lỗi trong flow cũ:", error);
        hideNotification();
    }
}



function showNotification(message) {
    const notificationPopup = document.getElementById('notification_popup');
    const popupText = document.getElementById('popup-text');

    popupText.textContent = message;
    notificationPopup.classList.add('show');
}

function hideNotification() {
    const notificationPopup = document.getElementById('notification_popup');
    notificationPopup.classList.remove('show');
}


function extractTopLevelComponentFiles(manifest) {
    const componentFiles = [];
    if (manifest.children) {
        manifest.children.forEach(child => {
            if (child.children && child.children.length > 0) {
                componentFiles.push(child.name);
            }
        });
    }
    return componentFiles;
}

function displayComponentFiles(files) {
    const fileListDiv = document.getElementById('componentFileList');
    fileListDiv.innerHTML = '';

    files.forEach(file => {
        const fileItem = document.createElement('div');
        fileItem.className = 'file-item';
        fileItem.innerHTML = `
            <input type="checkbox" name="componentFile" value="${file}" checked> <!-- Mặc định checked -->
            <label>${file}</label>
        `;
        fileListDiv.appendChild(fileItem);
    });
}

function loadNwdModelIframe(manifestBlobUrl, isIfcFile = false) {
    const iframe = document.createElement('iframe');
    iframe.id = '3dViewerIframe';
    iframe.src = '3dupgrade/index.html';
    iframe.style.width = '101%';
    iframe.style.height = '103vh';
    iframe.style.zIndex = '1100';
    iframe.style.overflow = 'hidden';
    iframe.style.left = '-8px';

    // Lắng nghe thông điệp về tiến độ tải từ viewer con
    window.addEventListener('message', function (event) {
        const data = event.data;

        if (data.action === 'totalProgressUpdate') {
            const progressPercent = data.percent.toFixed(2);

            const progressBar = document.getElementById('file-download-progress');
            const progressBarContainer = document.getElementById('file-download-progress-bar');
            if (progressBar) {
                progressBar.style.width = `${progressPercent}%`;
                progressBar.textContent = `${progressPercent}%`;
            }

            if (progressBarContainer) {
                progressBarContainer.style.display = 'block';
                if (progressPercent >= 100) {
                    setTimeout(() => {
                        progressBarContainer.style.display = 'none';
                    }, 1000);
                }
            }
        }
    });

    iframe.onload = () => {
        iframe.contentWindow.postMessage({ action: 'loadManifest', url: manifestBlobUrl }, '*');

        const queryParams = new URLSearchParams(window.location.search);
        const viewUrlParam = queryParams.get('viewurl');
        if (viewUrlParam) {
            iframe.contentWindow.postMessage({ action: 'setBasePath', basePath: viewUrlParam }, '*');
        }

        // Nếu là file IFC, tự động click nút startLoadModeButton và ẩn popup
        if (isIfcFile) {
            document.getElementById('startLoadModeButton').click();
            document.getElementById('loadmodePopup').style.display = 'none';
        }
    };

    const startLoadModeButton = document.getElementById('startLoadModeButton');
    if (startLoadModeButton && !startLoadModeButton.hasEventListener) {
        startLoadModeButton.addEventListener('click', function () {
            const loadMode = document.querySelector('input[name="loadMode"]:checked').value;

            hideNotification();

            iframe.contentWindow.postMessage({
                action: 'loadMode',
                loadMode: loadMode
            }, '*');

            const skippedFiles = Array.from(document.querySelectorAll('input[name="componentFile"]:not(:checked)'))
                .map(input => input.value);
            iframe.contentWindow.postMessage({
                action: 'skipFiles',
                skippedFiles: skippedFiles
            }, '*');

            document.getElementById('loadmodePopup').style.visibility = 'hidden';
        });
        startLoadModeButton.hasEventListener = true;
    }

    const progressBarContainer = document.getElementById('file-download-progress-bar');
    if (progressBarContainer) {
        progressBarContainer.style.display = 'none';
    }

    const container = document.getElementById('modelViewer');
    if (!container) {
        const newContainer = document.createElement('div');
        newContainer.id = 'modelViewer';
        newContainer.style.width = '100%';
        newContainer.style.height = '100vh';
        newContainer.style.overflow = 'hidden';
        newContainer.style.marginLeft = '-6px';
        newContainer.style.marginTop = '-6px';

        document.body.appendChild(newContainer);
        newContainer.appendChild(iframe);
    } else {
        const existingIframe = document.getElementById('3dViewerIframe');
        if (existingIframe) {
            existingIframe.parentNode.replaceChild(iframe, existingIframe);
        } else {
            container.appendChild(iframe);
        }
    }

    window.parent.postMessage({ type: 'onViewerLoaded', modelType: '3D' }, '*');
}


function checkManifestContent(manifestData) {
    const view3DTabButton = document.querySelector('.inner-tab-button[data-innertab="view3D"]');
    const sheetsTabButton = document.querySelector('.inner-tab-button[data-innertab="sheets"]');

    if (!manifestData.views || manifestData.views.length === 0 ||
        (manifestData.views.every(view => !view.ifcPath && !view.fbxPath))) {
        if (view3DTabButton) view3DTabButton.style.display = 'none';
        if (sheetsTabButton) {
            sheetsTabButton.click();
            sheetsTabButton.style.display = 'block';
        }
    } else {
        if (view3DTabButton) view3DTabButton.style.display = 'block';
    }
}

document.addEventListener('DOMContentLoaded', handleAppInitialization);

async function handleAppInitialization() {
    const queryParams = new URLSearchParams(window.location.search);
    const viewUrlParam = queryParams.get('viewurl');

    const manifestUrl = `${viewUrlParam}/Compilation/Manifest.json`;
    await loadManifest(manifestUrl);
}


let scene, camera;
let controls, renderer2;
const modal = document.getElementById("modelLoadingModal");
const loadingText = document.getElementById("loadingText");

async function fetchNwdAsBlob(fbxUrl) {
    try {
        const response = await fetch(fbxUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error("Failed to fetch nwd:", error);
        return null;
    }
}

async function loadNwdModel(fbxUrl) {
    modal.style.display = "block";
    updateLoadingMessage("Đang tải mô hình, vui lòng đợi...");
    window.parent.postMessage({ action: 'onViewerLoad' }, '*');

    const blob = await fetchNwdAsBlob(fbxUrl);
    if (!blob) {
        console.error("Failed to load nwd as blob");
        updateLoadingMessage("Không thể tải mô hình!");
        return;
    }
    viewer.context.renderer.postProduction.active = false;

    const objectURL = URL.createObjectURL(blob);
    const loader = new FBXLoader();

    loader.load(objectURL, (object) => {
        object.rotateX(Math.PI / 2);
        object.rotateY(Math.PI);
        viewer.context.scene.add(object);
        URL.revokeObjectURL(objectURL);

        let numObjects = 0;
        object.traverse((child) => {
            if (child.isMesh) {
                numObjects++;
                //child.material = new THREE.MeshPhongMaterial({
                //    color: 0xCCCCCC,
                //    emissive: 0x202020,
                //    specular: 0x555555,
                //    shininess: 30,
                //    side: THREE.DoubleSide
                //});
            }
        });


        updateLoadingMessage(`Đã xử lý xong mô hình, tổng có ${numObjects} phần tử được xử lý.`);
        setTimeout(hideModal, 8000);
        viewer.context.fitToFrame();

        fitToFrameCustom(object, viewer.context.getCamera(), viewer.context.scene);
    },
        (xhr) => {
            const percentComplete = Math.round((xhr.loaded / xhr.total) * 100);
            if (percentComplete < 100) {
                updateLoadingMessage(`Đang tải mô hình: ${percentComplete}%`);
            } else {
                updateLoadingMessage("Đã tải xong mô hình. Đang tiến hành Render. Vui lòng đơi.");
            }
        },
        (error) => {
            console.error('Error loading FBX model:', error);
            updateLoadingMessage("Lỗi khi tải mô hình!");
        });
    window.parent.postMessage({ action: 'onViewerLoaded' }, '*');
}


function updateLoadingMessage(message) {
    loadingText.innerText = message;
}

function hideModal() {
    modal.style.display = "none";
}

const closeModalButton = document.querySelector('.modal .close');
closeModalButton.addEventListener('click', () => {
    modal.style.display = "none";
});


function fitToFrameCustom(model, camera, scene) {
    const boundingBox = new THREE.Box3().setFromObject(model);

    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));

    cameraZ = Math.max(cameraZ, 10);

    camera.position.z = center.z + cameraZ;
    camera.lookAt(center);

    if (controls) {
        controls.target.set(center.x, center.y, center.z);
        controls.update();
    }
}

const currentState = {
    selectedViewName: '',
    selectedSheetName: ''
};
function selectView3D(name) {
    currentState.selectedViewName = name;
    currentState.selectedSheetName = '';
    updateHeaderName();
    highlightSelected();
}
function selectSheet(name) {
    currentState.selectedSheetName = name;
    currentState.selectedViewName = '';
    updateHeaderName();
    // Ensure the DOM is fully loaded
    if (document.readyState === 'complete') {
        highlightSelected();
    } else {
        document.addEventListener('DOMContentLoaded', highlightSelected);
    }
}

function updateHeaderName() {
    const headerNameElement = document.getElementById('header-name');
    const name = currentState.selectedViewName || currentState.selectedSheetName;
    headerNameElement.textContent = `Name: ${name}`;
}
function highlightSelected() {
    const previouslySelected = document.querySelectorAll('.tab-button.selected, .tab-content.selected, .layout-tab.selected');
    previouslySelected.forEach(element => {
        element.classList.remove('selected');
    });

    if (currentState.selectedViewName) {
        const selectedViewElement = document.querySelector(`[data-view3d-name="${currentState.selectedViewName}"]`);
        if (selectedViewElement) {
            selectedViewElement.classList.add('selected');
        }
    } else if (currentState.selectedSheetName) {
        const selectedSheetElement = document.querySelector(`[data-sheet-name="${currentState.selectedSheetName}"], [data-layout-name="${currentState.selectedSheetName}"]`);
        if (selectedSheetElement) {
            selectedSheetElement.classList.add('selected');
        }
    }
}




async function updateUIWithManifestData(manifestData) {
    const view3DContent = document.getElementById('view3D');
    const sheetsContent = document.getElementById('sheets');
    const view3DTabButton = document.querySelector('.inner-tab-button[data-innertab="view3D"]');
    const sheetsTabButton = document.querySelector('.inner-tab-button[data-innertab="sheets"]');

    view3DContent.innerHTML = '';
    sheetsContent.innerHTML = '';
    let has3DViews = false;

    manifestData.views.forEach(view => {
        let container3D;
        if (view.ifcPath && view.ifcPath.endsWith('.ifc')) {
            container3D = createViewContainer(view, 'view3D');
            has3DViews = true;
        } else if (view.fbxPath && view.fbxPath.endsWith('.fbx')) {
            container3D = createViewContainer(view, 'view3D');
            has3DViews = true;
        }

        if (container3D) {
            view3DContent.appendChild(container3D);
        }
    });

    manifestData.sheets.forEach(sheet => {
        if (sheet.dxfPath && sheet.dxfPath.endsWith('.dxf')) {
            const containerSheet = createViewContainer(sheet, 'sheet');
            sheetsContent.appendChild(containerSheet);
        }
    });
    manifestData.sheets.forEach(sheet => {
        if (sheet.pdfPath && sheet.pdfPath.endsWith('.pdf')) {
            const containerSheet = createViewContainer(sheet, 'sheet', 'pdf');
            sheetsContent.appendChild(containerSheet);
        }
    });

    if (manifestData.views.length > 0) {
        selectView3D(manifestData.views[0].name);
        if (manifestData.views[0].ifcPath) {
            loadIfc(`/api/load-ifc-file?path=${encodeURIComponent(manifestData.views[0].ifcPath)}`);
        } else if (manifestData.views[0].fbxPath) {
            loadNwdModel(`/api/load-fbx-file?path=${encodeURIComponent(manifestData.views[0].fbxPath)}`);
        }
        toggleViewerAndSheetDisplay('view3D');
        view3DTabButton.classList.add('active');
        sheetsTabButton.classList.remove('active');
    } else if (manifestData.sheets.length > 0) {
        selectSheet(manifestData.sheets[0].name);
        if (manifestData.sheets[0].dxfPath) {
            loadDXF(`/api/load-dxf-file?path=${encodeURIComponent(manifestData.sheets[0].dxfPath)}`);
        } else if (manifestData.sheets[0].pdfPath) {
            loadPDF(`/api/load-pdf-file?path=${encodeURIComponent(manifestData.sheets[0].pdfPath)}`);
        }
        toggleViewerAndSheetDisplay('sheets');
        view3DTabButton.classList.remove('active');
        sheetsTabButton.classList.add('active');
    }
}

function createViewContainer(view) {
    const container = document.createElement('div');
    container.className = 'thumbnail-container';

    // Xác định loại view và thiết lập attribute tương ứng
    let viewType = '';
    let viewPath = '';
    if (view.ifcPath && view.ifcPath.endsWith('.ifc')) {
        viewType = 'view3D';
        viewPath = view.ifcPath;
    } else if (view.fbxPath && view.fbxPath.endsWith('.fbx')) {
        viewType = 'view3D';
        viewPath = view.fbxPath;
    } else if (view.dxfPath && view.dxfPath.endsWith('.dxf')) {
        viewType = 'sheets';
        viewPath = view.dxfPath;
    } else if (view.pdfPath && view.pdfPath.endsWith('.pdf')) {
        viewType = 'sheets';
        viewPath = view.pdfPath;
    }

    container.setAttribute(`data-${viewType}-name`, view.name);

    const img = document.createElement('img');
    img.alt = `Thumbnail of ${view.name}`;
    img.className = 'thumbnail-image';
    img.src = view.thumbnail && view.thumbnail !== 'null' ? `/thumbnail/${encodeURIComponent(view.thumbnail)}` : '/resources/noimage.svg';
    container.appendChild(img);

    const name = document.createElement('div');
    name.className = 'thumbnail-name';
    name.textContent = view.name;
    container.appendChild(name);

    container.addEventListener('click', async () => {
        if (viewType === 'view3D') {
            selectView3D(view.name);
            if (view.ifcPath && view.ifcPath.endsWith('.ifc')) {
                await loadIfc(`/api/load-ifc-file?path=${encodeURIComponent(viewPath)}`);
            } else if (view.fbxPath && view.fbxPath.endsWith('.fbx')) {
                await loadNwdModel(`/api/load-fbx-file?path=${encodeURIComponent(viewPath)}`);
            }
            toggleViewerAndSheetDisplay('view3D');
        } else if (viewType === 'sheets') {
            selectSheet(view.name);
            if (viewPath.endsWith('.dxf')) {
                await loadDXF(`/api/load-dxf-file?path=${encodeURIComponent(viewPath)}`);
            } else if (viewPath.endsWith('.pdf')) {
                await loadPDF(`/api/load-pdf-file?path=${encodeURIComponent(viewPath)}`);
            }
            toggleViewerAndSheetDisplay('sheets');
        }
        highlightSelected();
    });

    return container;
}

function getDisplayName(fileName) {
    const parts = fileName.split('_');
    if (parts.length > 1 && parts[parts.length - 1].toLowerCase().includes('layout')) {
        return parts[parts.length - 1];
    }
    return "Model";
}


function initializeLayoutTabs(layouts) {
    const toolbar = document.getElementById('layout-toolbar');
    toolbar.innerHTML = '';

    layouts.forEach((layout) => {
        const button = document.createElement('button');
        const displayName = getDisplayName(layout.name);
        button.textContent = displayName;
        button.classList.add('layout-tab');

        button.onclick = function () {
            selectSheet(layout.name);
            loadDXF(`/api/load-dxf-file?path=${encodeURIComponent(layout.dxfPath)}`);
            toggleViewerAndSheetDisplay('sheets');
            highlightSelected();
        };

        toolbar.appendChild(button);
    });
}


function loadDXF(dxfUrl) {
    window.parent.postMessage({ action: 'onViewerLoad' }, '*');

    const iframe = document.getElementById('2dViewerIframe');
    if (iframe) {
        iframe.src = `2dupgrade/2Dviewer.html?dxfPath=${encodeURIComponent(dxfUrl)}`;
        iframe.style.display = 'block';
        toggleViewerAndSheetDisplay('sheets');
    }
}

function loadPDF(pdfUrl) {
    window.parent.postMessage({ action: 'onViewerLoad' }, '*');

    const iframe = document.getElementById('2dViewerIframe');
    if (iframe) {
        iframe.src = `2dupgrade/2Dviewer.html?dxfPath=${encodeURIComponent(pdfUrl)}`;
        iframe.style.display = 'block';
        toggleViewerAndSheetDisplay('sheets');
    }
}

window.addEventListener('message', function (event) {
    const { action, data, objectId, base64Image } = event.data || {};

    const messageHandlers = {
        // === 📥 Từ CDE → Viewer ===
        'onPinIssueModeActivate': () => sendMessageToViewer('onPinIssueModeActivate'),
        'onPinIssueModeInActivate': () => sendMessageToViewer('onPinIssueModeInActivate'),

        // GIỮ tên từ CDE là renderIssuePins, nhưng đổi tên khi gửi xuống viewer
        'renderIssuePins': () => {
            if (Array.isArray(data)) {
                sendMessageToViewer('onRenderIssuePins', { data });
            } else {
                console.warn('❌ Dữ liệu pins không hợp lệ:', data);
            }
        },

        'modelBrowser:open': () => sendMessageToViewer('modelBrowser:open'),
        'modelBrowser:close': () => sendMessageToViewer('modelBrowser:close'),
        'storeyBrowser:open': () => sendMessageToViewer('storeyBrowser:open'),
        'storeyBrowser:close': () => sendMessageToViewer('storeyBrowser:close'),

        'onLoadAdditionalModels': () => {
            if (Array.isArray(data)) {
                sendMessageToViewer('onLoadAdditionalModels', { additionalViewUrls: data });
            } else {
                console.warn('❌ Dữ liệu không hợp lệ cho onLoadAdditionalModels:', data);
            }
        },

        'onUnloadModels': () => {
            if (Array.isArray(data)) {
                sendMessageToViewer('onUnloadModels', { data });
            } else {
                console.warn('❌ Dữ liệu không hợp lệ cho onUnloadModels:', data);
            }
        },

        'onLocateObject': () => {
            if (objectId) {
                sendMessageToViewer('onLocateObject', { objectId });
            } else {
                console.warn('❌ Thiếu objectId cho onLocateObject');
            }
        },

        'onCaptureObjectImage': () => {
            if (objectId) {
                sendMessageToViewer('onCaptureObjectImage', { objectId });
            } else {
                console.warn('❌ Thiếu objectId cho onCaptureObjectImage');
            }
        },

        // === 📤 Từ Viewer → CDE ===
        'issueCreated': () => forwardMessageToParent('issueCreated', data),
        'onBimObjectClicked': () => forwardMessageToParent('onBimObjectClicked', data),
        'pinClicked': () => forwardMessageToParent('pinClicked', data),
        'onClickOnAPoint': () => forwardMessageToParent('onClickOnAPoint', data),

        'objectImageCaptured': () => {
            forwardMessageToParent('objectImageCaptured', {
                objectId,
                base64Image
            });
        }
    };



    if (messageHandlers[action]) {
        messageHandlers[action]();
    } else {
        if (event.source === window.parent) {
            sendMessageToViewer(action, data);
        } else {
            forwardMessageToParent(action, data);
        }
    }
});

/**
 * Gửi message xuống Viewer con (iframe)
 */
function sendMessageToViewer(action, data = {}) {
    const iframe = document.getElementById('3dViewerIframe');
    if (iframe?.contentWindow) {
        const message = { action, ...data };
        iframe.contentWindow.postMessage(message, "*");
    } else {
        console.warn("⚠️ Không tìm thấy iframe 3dViewerIframe");
    }
}

/**
 * Forward message từ Viewer lên CDE
 */
function forwardMessageToParent(action, data) {
    window.parent.postMessage({ action, data }, '*');
}


/**
 * Chuyển tiếp dữ liệu issue từ viewer lên CDE
 */
function forwardIssueToParent(issuePinData) {
    window.parent.postMessage({ action: 'issueCreated', data: issuePinData }, '*');
}


/////////////Markup//////////
//document.getElementById('apply-rect-style').addEventListener('click', () => {
//    if (html.currentRect) {
//        html.saveRectData();
//    }
//    document.getElementById('markup-edit-popup').style.display = 'none';
//});

//document.addEventListener('DOMContentLoaded', () => {
//    html.loadMarkups();
//});
//document.getElementById('cancel-rect-edit').addEventListener('click', () => {
//    html.cancelEdit();
//});
//document.getElementById('rect-stroke-width').addEventListener('input', () => {
//    html.applyStyleToCurrentRect();
//});
//document.getElementById('rect-color').addEventListener('input', () => {
//    html.applyStyleToCurrentRect();
//});

//document.getElementById('layers-markup-to-filter').addEventListener('change', function (event) {
//    const selectedLayer = event.target.value;
//    filterMarkupsByLayer(selectedLayer);
//    updateLayerDropdown();

//});

//function filterMarkupsByLayer(layer) {
//    const markupItems = document.querySelectorAll('.markup-item');
//    markupItems.forEach(item => {
//        const itemLayer = item.getAttribute('data-layer');
//        if (layer === 'all' || itemLayer === layer) {
//            item.style.display = 'flex';
//        } else {
//            item.style.display = 'none';
//        }
//    });
//}

//function getUniqueLayersFromMarkups() {
//    const layers = new Set();
//    for (let i = 0; i < localStorage.length; i++) {
//        const key = localStorage.key(i);
//        if (key && key.startsWith('rect-')) {
//            const markupData = JSON.parse(localStorage.getItem(key));
//            if (markupData.layer) {
//                layers.add(markupData.layer);
//            }
//        }
//    }
//    return Array.from(layers);
//}

//function updateLayerDropdown() {
//    const uniqueLayers = getUniqueLayersFromMarkups();
//    const layerDropdown = document.getElementById('markup-layer-filter');
//    const editPopupLayerDropdown = document.getElementById('markup-layer');
//    layerDropdown.innerHTML = editPopupLayerDropdown.innerHTML = '<option value="all">Tất cả</option>';

//    uniqueLayers.forEach(layer => {
//        const option = document.createElement('option');
//        option.value = option.textContent = layer;
//        layerDropdown.appendChild(option.cloneNode(true));
//        editPopupLayerDropdown.appendChild(option);
//    });
//}
//updateLayerDropdown();

//document.getElementById('manage-layers-button').addEventListener('click', function () {
//    document.getElementById('layer-management-modal').style.display = 'block';
//    loadLayersToModal();
//});
//function loadLayersToModal() {
//    const layerListDiv = document.getElementById('layer-list');
//    layerListDiv.innerHTML = '';

//    const layers = getUniqueLayersFromMarkups();
//    layers.forEach(layer => {
//        const layerDiv = document.createElement('div');
//        layerDiv.textContent = layer;
//        layerDiv.classList.add('layer-item');

//        const deleteButton = document.createElement('button');
//        deleteButton.textContent = 'Xóa';
//        deleteButton.onclick = () => deleteLayer(layer);
//        layerDiv.appendChild(deleteButton);

//        layerListDiv.appendChild(layerDiv);
//    });
//}

//function deleteLayer(layerName) {
//    let layers = getLayersFromLocalStorage();
//    layers = layers.filter(layer => layer !== layerName);
//    saveLayersToLocalStorage(layers);
//    updateLayerDropdown();
//    loadLayersToModal();
//    console.log('Deleted layer:', layerName);
//}

//document.getElementById('add-layer-button').addEventListener('click', function () {
//    const newLayerName = document.getElementById('new-layer-name').value;
//    if (newLayerName) {
//        addNewLayer(newLayerName);
//        document.getElementById('new-layer-name').value = '';
//    }
//});

//document.getElementById('close-modal-button').addEventListener('click', function () {
//    document.getElementById('layer-management-modal').style.display = 'none';
//});

//function addNewLayer(layerName) {
//    const layers = getLayersFromLocalStorage();
//    if (!layers.includes(layerName)) {
//        layers.push(layerName);
//        saveLayersToLocalStorage(layers);
//        updateLayerDropdown();
//        loadLayersToModal();
//        console.log('Added new layer:', layerName);
//    } else {
//        console.log('Layer already exists:', layerName);
//    }
//}
//function getLayersFromLocalStorage() {
//    const layers = JSON.parse(localStorage.getItem('layers')) || [];
//    return layers;
//}
//function saveLayersToLocalStorage(layers) {
//    localStorage.setItem('layers', JSON.stringify(layers));
//}


//document.addEventListener('DOMContentLoaded', updateLayerDropdown);


///////////////////////////////////////////////////////End of slidebar//////////////////////////
///Popup notification
function showPopup(type, message) {
    const popup = document.getElementById('notification_popup');
    const popupContent = popup.querySelector('.popup-content');

    popup.classList.remove('fade-out');
    popupContent.textContent = message;

    if (type === 'info') {
        popupContent.className = 'popup-content info-popup-content';
    } else if (type === 'error') {
        popupContent.className = 'popup-content error-popup-content';
    }

    popup.style.display = 'block';

    setTimeout(() => {
        popup.classList.add('fade-out');

        setTimeout(() => {
            popup.style.display = 'none';
        }, 300);
    }, 5000);
}
function updateInfoPopupContent(content) {
    const infoPopupContent = document.getElementById('info-popup-content');
    if (infoPopupContent) {
        infoPopupContent.innerHTML = content;
    } else {
        console.error('Element #info-popup-content not found');
    }
}



///////////////////////////////////////////////////Toolbar/////


//Active button hilight
document.addEventListener('DOMContentLoaded', function () {
    const buttons = document.querySelectorAll('.toolbar-button');

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            buttons.forEach(innerButton => {
                innerButton.classList.remove('highlighted');
            });

            button.classList.add('highlighted');
        });
    });

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            buttons.forEach(button => {
                button.classList.remove('highlighted');
            });
        }
    });
});

// open button
document.addEventListener('DOMContentLoaded', () => {
    const button_open = document.getElementById('open_button');
    button_open.addEventListener('click', () => {
        inputElement.click();

    });
});


//Walk
let keyStates = {};

function handleKeyDownWalkMode(event) {
    keyStates[event.key.toLowerCase()] = true;
}

function handleKeyUpWalkMode(event) {
    keyStates[event.key.toLowerCase()] = false;
}

function updateMovement(delta) {
    const speed = 2.5 * delta;
    const movementX = new THREE.Vector3();
    const movementY = new THREE.Vector3();

    if (keyStates['w']) viewer.context.ifcCamera.cameraControls.forward(speed, true);
    if (keyStates['s']) viewer.context.ifcCamera.cameraControls.forward(-speed, true);
    if (keyStates['a']) movementX.x -= speed;
    if (keyStates['d']) movementX.x += speed;
    if (keyStates['q']) movementY.y += speed;
    if (keyStates['e']) movementY.y -= speed;

    viewer.context.ifcCamera.cameraControls.truck(movementX.x, movementY.y, false);
}


document.addEventListener('DOMContentLoaded', () => {
    const walk_button = document.getElementById('walk_button');
    let walking = false;
    let lastCameraPosition = new THREE.Vector3();
    let lastCameraRotation = new THREE.Euler();
    const clock = new THREE.Clock();

    const minimapContainer = document.getElementById('minimap-container');
    const minimapImage = new Image();
    minimapContainer.appendChild(minimapImage);
    const compassIcon = document.createElement('img');
    minimapContainer.appendChild(compassIcon);

    walk_button.addEventListener('click', toggleWalkingMode);

    document.addEventListener('keydown', (event) => {
        if (event.key === "Escape") {
            if (walking) {
                toggleWalkingMode();
            }
        }
    });

    function toggleWalkingMode() {
        walking = !walking;
        viewer.context.ifcCamera.setNavigationMode(walking ? 1 : 0);
        minimapContainer.style.display = walking ? 'flex' : 'none';

        if (walking) {
            document.addEventListener('keydown', handleKeyDownWalkMode);
            document.addEventListener('keyup', handleKeyUpWalkMode);
            showPopup('infor', 'Chế độ đi bộ được bật');
            const measuringInstructions = `
            <div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.6;">
    <h3>Hướng dẫn điều khiển:</h3>
    <p>Di chuyển trong không gian 3D bằng các phím sau:</p>
    <ul style="list-style: none; padding: 0;">
        <li><strong>W:</strong> Tiến về phía trước</li>
        <li><strong>S:</strong> Lùi về phía sau</li>
        <li><strong>A:</strong> Sang trái</li>
        <li><strong>D:</strong> Sang phải</li>
        <li><strong>E:</strong> Di chuyển lên cao</li>
        <li><strong>Q:</strong> Di chuyển xuống thấp</li>
    </ul>
    <p>Sử dụng chuột:</p>
    <ul style="list-style: none; padding: 0;">
        <li><strong>Click và giữ chuột trái:</strong> Quay đầu nhìn xung quanh</li>
    </ul>
</div>

            `;
            updateInfoPopupContent(measuringInstructions);
            lastCameraPosition.copy(viewer.context.ifcCamera.perspectiveCamera.position);
            lastCameraRotation.copy(viewer.context.ifcCamera.perspectiveCamera.rotation);

            animate();
        } else {
            document.removeEventListener('keydown', handleKeyDownWalkMode);
            document.removeEventListener('keyup', handleKeyUpWalkMode);
            showPopup('infor', 'Đang ở chế độ xem 3D thường');
            walk_button.classList.remove('highlighted');
        }
    }

    function animate() {
        requestAnimationFrame(animate);
        const delta = clock.getDelta();
        updateMovement(delta);

        const currentCameraPosition = viewer.context.ifcCamera.perspectiveCamera.position;
        const currentCameraRotation = viewer.context.ifcCamera.perspectiveCamera.rotation;

        if (!lastCameraPosition.equals(currentCameraPosition) || !lastCameraRotation.equals(currentCameraRotation)) {
            lastCameraPosition.copy(currentCameraPosition);
            lastCameraRotation.copy(currentCameraRotation);

            topViewCamera.position.set(currentCameraPosition.x, currentCameraPosition.y + 1, currentCameraPosition.z);
            topViewCamera.lookAt(new THREE.Vector3(currentCameraPosition.x, currentCameraPosition.y, currentCameraPosition.z));
            topViewCamera.rotateZ(currentCameraRotation.z);
            topViewCamera.rotateZ(Math.PI);

            const screenshotUrl = viewer.context.renderer.newScreenshot(topViewCamera, dimensions);
            minimapImage.src = screenshotUrl;

            const rotationDegrees = (currentCameraRotation.y * 180 / Math.PI) + 180;
            compassIcon.style.transform = `translate(-50%, -50%) rotate(${rotationDegrees}deg)`;
        }
    }

    const frustumSize = 13;
    const aspect = 400 / 400;
    const topViewCamera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 0.1, 60);
    topViewCamera.up.set(0, 0, 1);
    const dimensions = new THREE.Vector2(230, 230);
});


//Camerasetting_button
document.addEventListener('DOMContentLoaded', () => {
    const camerasetting_button = document.getElementById('camerasetting_button');
    const walk_button = document.getElementById('walk_button');
    let walk = true;

    camerasetting_button.addEventListener('click', () => {
        walk = !walk;
        viewer.context.ifcCamera.toggleProjection();

        walk_button.disabled = !walk;

        if (!walk) {
            walk_button.classList.add('disabled');
            showPopup('info', "Camera đang ở trạng thái projection")
        } else {
            walk_button.classList.remove('disabled');
            showPopup('info', "Camera đang ở trạng thái perspective")
            camerasetting_button.classList.remove('highlighted');

        }
        const measuringInstructions = `
        <ul>
            <li>Click để chuyển sang chế độ Camera khác</li>
            <li>Chỉ có thể đi bộ ở chế độ Perspective</li>
        </ul>
    `;
        updateInfoPopupContent(measuringInstructions);

    });
});

// DIM Button
let isMeasuring = false;

document.addEventListener('DOMContentLoaded', () => {
    const button_dim = document.getElementById('dim_button');

    button_dim.addEventListener('click', toggleMeasurementMode);

    function toggleMeasurementMode() {
        isMeasuring = !isMeasuring;
        viewer.dimensions.active = isMeasuring;
        viewer.dimensions.previewActive = isMeasuring;
        viewer.dimensions.snapDistance = 0.02;

        if (isMeasuring) {
            showPopup('info', 'Chế độ đo kích thước được bật. Hãy double click để đo');
            window.addEventListener('dblclick', handleMeasurementCreation);
        } else {
            showPopup('info', 'Đã thoát chế độ đo đạc');
            window.removeEventListener('dblclick', handleMeasurementCreation);
        }
    }
    function updatePreviewElement() {
        const htmlPreview = document.createElement('div');
        htmlPreview.className = 'ifcjs-dimension-preview';
        htmlPreview.style.width = "10px";
        htmlPreview.style.height = "10px";
        htmlPreview.style.borderRadius = "50%";
        htmlPreview.style.backgroundColor = "orange";
        htmlPreview.style.border = "2px solid white";
        htmlPreview.style.position = "absolute";
        htmlPreview.style.display = "block";
        htmlPreview.style.transform = "translate(-50%, -50%)";

        viewer.dimensions.setPreviewElement(htmlPreview);
    }
    updatePreviewElement();

    function handleMeasurementCreation() {
        if (isMeasuring) {
            viewer.dimensions.create();
        }
    }

    window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' && isMeasuring) {
            toggleMeasurementMode();
        }
    });
});


// Section view
let isClipperActive = false;

document.addEventListener('DOMContentLoaded', () => {
    const sectionViewButton = document.getElementById('section_button');

    sectionViewButton.addEventListener('click', () => {
        isClipperActive = !isClipperActive;
        viewer.clipper.toggle();

        sectionViewButton.classList.toggle('highlighted', isClipperActive);
        const measuringInstructions = `
        <ul>
            <li>Double Click vào mặt phẳng bất kỳ để cắt</li>
            <li>Giữ chuột trái và di để nhìn</li>
            <li>Click vào mũi tên ấn Delete để xóa</li>
        </ul>
    `;
        updateInfoPopupContent(measuringInstructions);

        showPopup('info', isClipperActive ? 'Chế độ cắt mặt đối tượng 3D được BẬT' : 'Chế độ cắt mặt đối tượng 3D được TẮT');
    });

    window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' && isClipperActive) {
            isClipperActive = false;
            viewer.clipper.toggle();
            sectionViewButton.classList.remove('highlighted');
            showPopup('info', 'Đã thoát khỏi chế độ cắt mặt đối tượng 3D');
        }
    });
});


function getIdsFromViewUrl() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const viewurl = params.get('viewurl');

    if (viewurl) {
        const cleanedViewUrl = viewurl.startsWith('/') ? viewurl.substring(1) : viewurl;
        const parts = cleanedViewUrl.split('/');
        if (parts.length >= 3) {
            const siteID = parts[0];
            const projectID = parts[1];
            const versionID = parts[2];
            return { siteID, projectID, versionID };
        } else {
            console.error('ViewURL format is incorrect');
            return null;
        }
    } else {
        console.error('ViewURL is not available');
        return null;
    }
}

function getAccessToken() {
    return fetch('/api/get-access-token')
        .then(response => response.json())
        .then(data => data.accessToken)
        .catch(error => console.error('Error fetching access token:', error));
}

function fetchFileVersionDetails(versionID) {
    getAccessToken().then(accessToken => {
        const fileByVersionUrl = `https://api-docs.buildtab.vn/api/v1/files/get-file-by-version-id?versionId=${versionID}`;
        fetch(fileByVersionUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                updateUIWithFileVersionDetails(data);
            })
            .catch(error => {
                console.error('Error fetching file version details:', error);
            });
    });
}


document.getElementById('compare-button').addEventListener('click', function () {

    const comparingInstructions = `
                <ul>
                    <li>Hãy chọn 1 version muốn so sánh</li>
                    <li>Version hiện tại sẽ được đánh dấu tự động</li>
                </ul>
            `;
    updateInfoPopupContent(comparingInstructions);

    const { versionID } = getIdsFromViewUrl();
    if (versionID) {
        fetchFileVersionDetails(versionID);
    } else {
        console.error('No version ID found in URL');
    }
});

let viewUrls = [];
function updateUIWithFileVersionDetails(data) {
    const versionsList = document.getElementById('versionsList');
    versionsList.innerHTML = '';
    viewUrls = [];
    const currentVersionID = getIdsFromViewUrl().versionID;

    data.versions.forEach(version => {
        const versionItem = document.createElement('div');
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.id = version._id;
        checkbox.value = version.viewUrl;
        checkbox.setAttribute('data-createdat', version.createdAt);
        const label = document.createElement('label');
        label.htmlFor = version._id;
        const isCurrentVersion = version._id === currentVersionID;
        label.textContent = `Version ${version.versionNumber} - ${new Date(version.createdAt).toLocaleDateString()}${isCurrentVersion ? ' (current version)' : ''}`;

        versionItem.appendChild(checkbox);
        versionItem.appendChild(label);
        versionsList.appendChild(versionItem);

        if (isCurrentVersion) {
            checkbox.checked = true;
            checkbox.disabled = true;
        }

        viewUrls.push(version.viewUrl);
    });

    document.querySelectorAll('#versionsList input[type="checkbox"]:not([disabled])').forEach(checkbox => {
        checkbox.addEventListener('change', handleCheckboxChange);
    });

    document.getElementById('comparePopup').style.display = 'block';
}

function handleCheckboxChange() {
    const checkboxes = document.querySelectorAll('#versionsList input[type="checkbox"]:not([disabled])');
    const checkedCheckboxes = Array.from(checkboxes).filter(checkbox => checkbox.checked);

    if (checkedCheckboxes.length > 2) {
        checkedCheckboxes[checkedCheckboxes.length - 1].checked = false;
        showPopup('info', 'Bạn chỉ được chọn tối đa 2 phiên bản để so sánh!');
        return;
    }

    checkboxes.forEach(checkbox => {
        checkbox.disabled = false;
    });

    if (checkedCheckboxes.length == 2) {
        const uncheckedCheckboxes = Array.from(checkboxes).filter(checkbox => !checkbox.checked);
        uncheckedCheckboxes.forEach(checkbox => {
            checkbox.disabled = true;
        });
    }
}



function downloadAndCacheGeometry(siteId, projectId, versionId) {
    return new Promise((resolve, reject) => {
        const url = `/api/download-geometry/${siteId}/${projectId}/${versionId}`;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(data => {
                const cacheKey = `${versionId}_3D_Geometry`;
                localStorage.setItem(cacheKey, data);
                //  console.log(`Geometry data for version ${versionId} cached.`);
                resolve(versionId);
            })
            .catch(error => {
                console.error('Error downloading geometry data:', error);
                reject(error);
            });
    });
}

document.getElementById('compareVersions').addEventListener('click', function () {
    const selectedVersions = document.querySelectorAll('#versionsList input[type="checkbox"]:checked');

    if (selectedVersions.length !== 2) {
        showPopup('infor', 'Bạn chỉ được chọn 2 phiên bản để so sánh!');
        return;
    }
    const selectedViewUrls = Array.from(selectedVersions).map(input => input.value);

    const sortedVersions = Array.from(selectedVersions).sort((a, b) => {
        const dateA = new Date(a.getAttribute('data-createdat'));
        const dateB = new Date(b.getAttribute('data-createdat'));
        return dateA - dateB;
    });

    const newerVersion = sortedVersions[sortedVersions.length - 1];
    const newerVersionViewUrl = newerVersion.value;

    // console.log("URL phiên bản mới hơn: ", newerVersionViewUrl);

    clearPreviousGeometryData();

    const downloadPromises = selectedViewUrls.map(url => {
        const [siteId, projectId, versionId] = url.startsWith('/') ? url.substring(1).split('/') : url.split('/');
        return downloadAndCacheGeometry(siteId, projectId, versionId);
    });

    Promise.all(downloadPromises)
        .then(versionIds => {
            const geometryData = versionIds.map(id => JSON.parse(localStorage.getItem(`${id}_3D_Geometry`)));
            const comparisonResults = [];
            for (let i = 0; i < geometryData.length - 1; i++) {
                const result = compareGeometryData(geometryData[i], geometryData[i + 1]);
                comparisonResults.push(result);
            }
            showComparisonResults(comparisonResults);

        })
        .catch(error => {
            console.error("Error downloading geometry data:", error);
        });

    document.getElementById('comparePopup').style.display = 'none';
});



function applyComparisonResultsToModel(comparisonResults) {
    const revitIDExpressIDMap = JSON.parse(sessionStorage.getItem('revitIDExpressIDMap') || '{}');

    const modelID = 0;
    viewer.IFC.selector.fadeAwayModels();

    comparisonResults.forEach(result => {
        const addedExpressIDs = result.added.map(item => revitIDExpressIDMap[item.id]).filter(id => id !== undefined);
        const removedExpressIDs = result.removed.map(item => revitIDExpressIDMap[item.id]).filter(id => id !== undefined);
        const modifiedExpressIDs = result.modified.map(item => revitIDExpressIDMap[item.id]).filter(id => id !== undefined);

        if (addedExpressIDs.length > 0) {
            //console.log(`Thay đổi màu cho thêm: ExpressIDs ${addedExpressIDs.join(', ')}`);
            changeColorOfSelectedObjects(modelID, addedExpressIDs, "#00ff00");
        }

        if (removedExpressIDs.length > 0) {
            //console.log(`Thay đổi màu cho xóa: ExpressIDs ${removedExpressIDs.join(', ')}`);
            changeColorOfSelectedObjects(modelID, removedExpressIDs, "#ff0000");
        }

        if (modifiedExpressIDs.length > 0) {
            // console.log(`Thay đổi màu cho sửa: ExpressIDs ${modifiedExpressIDs.join(', ')}`);
            changeColorOfSelectedObjects(modelID, modifiedExpressIDs, "#ffff00");
        }
    });
}


function changeColorOfSelectedObjects(modelID, ids, colorHex) {
    const material = new THREE.MeshLambertMaterial({ color: new THREE.Color(colorHex) });

    const config = {
        modelID: modelID,
        scene: viewer.context.getScene(),
        ids: ids,
        removePrevious: true,
        material: material
    };

    viewer.IFC.loader.ifcManager.createSubset(config);
}

function clearPreviousGeometryData() {
    Object.keys(localStorage).forEach(key => {
        if (key.endsWith('_3D_Geometry')) {
            localStorage.removeItem(key);
        }
    });
}

function compareGeometryData(geometry1, geometry2) {
    let added = [], removed = [], modified = [];

    Object.keys(geometry2).forEach(id => {
        if (!geometry1[id]) {
            added.push({ id: id, data: geometry2[id] });
        } else {
            let changes = [];
            ['Min', 'Max', 'Center'].forEach(property => {
                if (JSON.stringify(geometry1[id][property]) !== JSON.stringify(geometry2[id][property])) {
                    changes.push({
                        property: property,
                        from: geometry1[id][property],
                        to: geometry2[id][property]
                    });
                }
            });
            if (changes.length > 0) {
                modified.push({ id: id, changes: changes });
            }
        }
    });

    Object.keys(geometry1).forEach(id => {
        if (!geometry2[id]) {
            removed.push({ id: id, data: geometry1[id] });
        }
    });

    return { added, removed, modified };
}

let currentComparisonResults = null;
let isComparisonPopupVisible = false;

function showComparisonResults(comparisonResults) {
    currentComparisonResults = comparisonResults;
    const resultsContainer = document.getElementById('comparisonResultsContainer');
    const addedCountEl = document.getElementById('addedCount');
    const removedCountEl = document.getElementById('removedCount');
    const modifiedCountEl = document.getElementById('modifiedCount');

    const summaryCounts = { added: 0, removed: 0, modified: 0 };
    resultsContainer.innerHTML = '';

    comparisonResults.forEach((result, index) => {
        summaryCounts.added += result.added.length;
        summaryCounts.removed += result.removed.length;
        summaryCounts.modified += result.modified.length;

        const resultDiv = document.createElement('div');
        resultDiv.classList.add("comparison-result-item");
        let contentHtml = `<h3>So sánh ${index + 1}</h3>`;
        contentHtml += generateComparisonDetailList('Đã thêm', result.added);
        contentHtml += generateComparisonDetailList('Đã xóa', result.removed);
        contentHtml += generateComparisonDetailList('Đã sửa đổi', result.modified);
        resultDiv.innerHTML = contentHtml;
        resultsContainer.appendChild(resultDiv);
    });

    addedCountEl.textContent = summaryCounts.added;
    removedCountEl.textContent = summaryCounts.removed;
    modifiedCountEl.textContent = summaryCounts.modified;
    updateTitleAndColor();
    document.getElementById('comparisonResultPopup').style.display = 'block';
    applyComparisonResultsToModel(comparisonResults);
    isComparisonPopupVisible = true;
}
document.getElementById('comparisonResultsContainer').addEventListener('click', function (e) {
    const item = e.target.closest('.comparison-result-item');

    if (item) {
        const revitIDMatch = item.textContent.match(/ID: (\d+)/);
        if (revitIDMatch) {
            const revitID = revitIDMatch[1];
            highlightAndZoomToObject(revitID);
        }
    }
});

function highlightAndZoomToObject(revitID) {
    const revitIDExpressIDMap = JSON.parse(sessionStorage.getItem('revitIDExpressIDMap') || '{}');
    const expressID = revitIDExpressIDMap[revitID];
    if (expressID !== undefined) {
        viewer.IFC.selector.pickIfcItemsByID(0, [parseInt(expressID)], true, true);
        //viewer.IFC.selector.highlightIfcItemsByID(0, [parseInt(expressID)], true, true);

    } else {
        console.error('Không tìm thấy ID tương ứng với RevitID:', revitID);
    }
}

function generateComparisonDetailList(title, items) {
    if (items.length === 0) return '';
    let contentHtml = `<p><strong>${title}:</strong></p><ul>`;
    items.forEach(item => {
        contentHtml += `<li class="comparison-result-item" data-revitid="${item.id}">ID: ${item.id}</li>`;
    });
    contentHtml += `</ul>`;
    return contentHtml;
}

document.getElementById('closePopupBtn').addEventListener('click', function () {
    document.getElementById('comparisonResultPopup').style.display = 'none';
    isComparisonPopupVisible = false;
});

['added', 'removed', 'modified'].forEach(type => {
    document.getElementById(`show${type.charAt(0).toUpperCase() + type.slice(1)}`).addEventListener('click', function () {
        const filteredResults = currentComparisonResults.map(result => result[type]);
        showFilteredResults(filteredResults, type);
    });
});

function showFilteredResults(filteredResults, type) {
    const resultsContainer = document.getElementById('comparisonResultsContainer');
    resultsContainer.innerHTML = '';

    filteredResults.forEach(items => {
        items.forEach(item => {
            const itemDiv = document.createElement('div');
            itemDiv.classList.add('comparison-result-item');
            itemDiv.textContent = `ID: ${item.id}`;
            resultsContainer.appendChild(itemDiv);
        });
    });
}

function updateTitleAndColor(type) {
    const popupTitle = document.querySelector('#comparisonResultPopup h2');

    switch (type) {
        case 'added':
            popupTitle.textContent = 'Các đối tượng đã Thêm';
            popupTitle.style.color = '#4CAF50';
            break;
        case 'removed':
            popupTitle.textContent = 'Các đối tượng bị Xóa';
            popupTitle.style.color = '#F44336';
            break;
        case 'modified':
            popupTitle.textContent = 'Các đối tượng bị Sửa';
            popupTitle.style.color = '#FFC107';
            break;
        default:
            popupTitle.textContent = 'Bảng thống kê tổng các thay đổi';
            popupTitle.style.color = '#2876c9';
            break;
    }
}
document.addEventListener('DOMContentLoaded', () => {
    const addedBtn = document.getElementById('showAdded');
    const removedBtn = document.getElementById('showRemoved');
    const modifiedBtn = document.getElementById('showModified');


    addedBtn.addEventListener('click', () => updateTitleAndColor('added'));
    removedBtn.addEventListener('click', () => updateTitleAndColor('removed'));
    modifiedBtn.addEventListener('click', () => updateTitleAndColor('modified'));

});


// Issue

let currentIssueSprite = null;
let isPinIssue = false;
let issuePins = [];
let issueLabels = [];


document.addEventListener('DOMContentLoaded', () => {
    const buttonIssue = document.getElementById('issue-button');

    buttonIssue.addEventListener('click', toggleIssueMode);

    window.addEventListener('message', (event) => {
        if (event.data.action === 'onPinIssueModeActivate') {
            if (!isPinIssue) {
                toggleIssueMode(true);
            }
        } else if (event.data.action === 'onPinIssueModeInActivate') {
            if (isPinIssue) {
                toggleIssueMode(false);
            }
        } else if (event.data.action === 'onIssueActivate') {
            if (!isPinIssue) {
                toggleIssueMode(true);
            }
        } else if (event.data.action === 'renderIssuePins') {
            renderIssuePins(event.data.data);
            if (event.data.data.length > 0) {
                const firstIssue = event.data.data[0];
                moveCameraToPosition(firstIssue.cameraPosition, firstIssue.cameraTarget);
            }
        }
    });

    function renderIssuePins(issuePinsData) {
        issuePinsData.forEach(data => {
            const { spritePosition, cameraPosition, cameraTarget, labelText, id } = data;
            const sprite = createIssueSprite(spritePosition, cameraPosition, cameraTarget, id);
            const label = createIssueLabel(labelText, id);
            sprite.add(label);
            issuePins.push({ sprite, label, data, id });
        });
    }

    function createIssueSprite(position, cameraPosition, cameraTarget, id) {
        const texture = new THREE.TextureLoader().load('./resources/issueonviewer.svg');
        const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.position.set(position.x, position.y, position.z);
        sprite.scale.set(1.5, 1.5, 1.5);
        sprite.userData = { id, cameraPosition, cameraTarget };
        viewer.context.scene.add(sprite);
        return sprite;
    }

    function createIssueLabel(labelText, id) {
        const div = document.createElement('div');
        div.className = 'label';
        div.textContent = labelText;
        div.dataset.issueId = id;
        const label = new CSS2DObject(div);
        label.position.set(0, 0.2, 0);
        return label;
    }

    document.addEventListener('click', function (event) {
        if (event.target.classList.contains('label')) {
            const labelId = event.target.dataset.issueId;
            const issue = issuePins.find(issue => issue.id === labelId);
            if (issue) {
                moveCameraToPosition(issue.data.cameraPosition, issue.data.cameraTarget);
                window.parent.postMessage({ action: 'onClickOnAPoint', id: labelId }, '*');
            }
        }
    });


    function toggleIssueMode(forceState = null) {
        const previousState = isPinIssue;
        if (forceState !== null) {
            isPinIssue = forceState;
        } else {
            isPinIssue = !isPinIssue;
        }

        // Gửi thông báo trạng thái đến frame mẹ
        if (previousState !== isPinIssue) { // Chỉ gửi thông báo nếu có thay đổi
            const action = isPinIssue ? 'onPinIssueModeActivated' : 'onPinIssueModeDeactivated';
            window.parent.postMessage({ action: action, state: isPinIssue }, '*');
        }

        const issueInstructions = isPinIssue ? `
        <ul>
            <li>Hãy double click vào vị trí bạn muốn tạo Issue</li>
            <li>Muốn đổi vị trí issue vừa tạo, hãy double click vào vị trí mới</li>
        </ul>
    ` : `<ul><li>Chế độ Pin Issue đã bị vô hiệu hóa. Nhấn nút để kích hoạt lại.</li></ul>`;

        updateInfoPopupContent(issueInstructions);
        buttonIssue.classList.toggle('highlighted', isPinIssue);

        if (isPinIssue) {
            window.addEventListener('dblclick', createOrUpdateIssueAtMousePosition);
            showAllLabels();
        } else {
            window.removeEventListener('dblclick', createOrUpdateIssueAtMousePosition);
            clearIssuesVisibility();
        }
    }


    function clearIssuesVisibility() {
        issuePins.forEach(({ sprite, label }) => {
            sprite.visible = false;
            if (label && label.element) {
                label.element.classList.add('hidden');
            }
        });

        if (currentIssueSprite) {
            currentIssueSprite.visible = false;
            currentIssueSprite.children.forEach(child => {
                if (child.element) child.element.classList.add('hidden');
            });
        }
    }

    function showAllLabels() {
        issuePins.forEach(({ sprite, label }) => {
            sprite.visible = true;
            if (label && label.element) {
                label.element.classList.remove('hidden');
            }
        });

        if (currentIssueSprite) {
            currentIssueSprite.visible = true;
            currentIssueSprite.children.forEach(child => {
                if (child.element) child.element.classList.remove('hidden');
            });
        }
    }


    let issueIdCounter = 0;

    function createOrUpdateIssueAtMousePosition(event, dynamicLabelText = "Vị trí Vấn đề", providedId = null) {
        if (!isPinIssue) return;

        const issueId = providedId || `issue_${++issueIdCounter}`;

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        const camera = viewer.context.getCamera();
        raycaster.setFromCamera(mouse, camera);

        const raycastResult = viewer.context.castRayIfc(raycaster);
        if (raycastResult) {
            const screenshotData = takeScreenshotAtPosition(raycastResult.point, camera);
            if (!currentIssueSprite) {
                currentIssueSprite = createIssueSprite(raycastResult.point, issueId);
            } else {
                currentIssueSprite.position.copy(raycastResult.point);
            }
            const newLabel = createIssueLabel(dynamicLabelText, () => {
                moveCameraToPosition(screenshotData.cameraPosition, screenshotData.cameraTarget);
            }, issueId);
            replaceIssueLabel(currentIssueSprite, newLabel);

            postIssueData({
                spritePosition: raycastResult.point,
                cameraPosition: screenshotData.cameraPosition,
                cameraTarget: screenshotData.cameraTarget,
                screenshotUrl: screenshotData.screenshotUrl,
            });
        }
    }

    function replaceIssueLabel(sprite, newLabel) {
        sprite.children.forEach(child => {
            if (child instanceof CSS2DObject) {
                sprite.remove(child);
            }
        });
        sprite.add(newLabel);
    }

    function postIssueData(issuePinData) {
        window.parent.postMessage({ action: 'issueCreated', data: issuePinData }, '*');
    }


        function takeScreenshotAtPosition(position, camera) {
            const screenshotCamera = new THREE.PerspectiveCamera(
                55, camera.aspect, 0.1, camera.far
            );

            const offset = new THREE.Vector3(1.5, 1, 1.5);
            const cameraPosition = position.clone().add(offset);
            screenshotCamera.position.copy(cameraPosition);
            screenshotCamera.lookAt(position);

            const dimensions = new THREE.Vector2(1920, 1080);
            const screenshotUrl = viewer.context.renderer.newScreenshot(screenshotCamera, dimensions);

            return {
                screenshotUrl: screenshotUrl,
                cameraPosition: cameraPosition,
                cameraTarget: position
            };
        }
        function moveCameraToPosition(cameraPosition, cameraTarget) {
            const offset = new THREE.Vector3().subVectors(cameraPosition, cameraTarget).normalize().multiplyScalar(10);
            const newPosition = new THREE.Vector3().addVectors(cameraPosition, offset);
            const controls = viewer.context.ifcCamera.controls;
            controls.moveTo(newPosition.x, newPosition.y, newPosition.z, true);
            controls.setLookAt(newPosition.x, newPosition.y, newPosition.z, cameraTarget.x, cameraTarget.y, cameraTarget.z, true);
        }


        window.addEventListener('keydown', (event) => {
            if (event.key === 'Escape' && isPinIssue) {
                toggleIssueMode();
                clearIssuesVisibility();

                window.parent.postMessage({ action: 'onPinIssueModeExited' }, '*');
                showPopup('info', 'Đã thoát khỏi chế độ Pin issue');
            }
        });
});